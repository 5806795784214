.container {
    background: white;
    min-width: 35rem;
    max-width: 60rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: calc(100vh - 14rem);
}

.container.center {
    justify-content: center;
}

.container .items {
    width: 100%;
}

.container .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.container .title h2 {
    margin-bottom: 0;
    margin-right: auto;
    margin-left: 1rem;
}

.container .title .back {
    color: black !important;
    background: white !important;
    border: 0 !important;
}

.container .object {
    border: .1rem rebeccapurple solid;
    border-radius: 0.5rem;
    margin: .5rem;
    padding: 1rem;
    width: 100%;
}

.container .title .views{
    margin-left: 1rem;
    margin-right: 1rem;
}

.container .title .views .view{
    background: white !important;
    border: 0;
    color: rebeccapurple;
    margin-left: .25rem;
}

.container .title .views .view.active{
    border: 1px black solid;
}

.container .object .title {
    margin-top: 0;
}

.container .object .piece h5 {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 1rem;
}

.container .object .empty {
    margin-left: 1rem;
    text-align: justify;
}

.container .object .description {
    text-align: justify;
    margin: .5rem;
    margin-left: 1rem;
    list-style-position: inside;
}

.container .object .mergeRequests .object.mergeRequest{
    padding: 0;
    padding-left: .5rem;
    padding-top: .5rem;
}

.container .object .mergeRequests .object.mergeRequest .title h5{
    margin-bottom: 0;
}

.container .object .mergeRequests .object.mergeRequest .title .link{
    margin-right: .5rem;
}

.container .object .description p{
    margin: 0;
}

.container .link {
    margin-right: .5rem;
    color: rebeccapurple;
    -ms-transform: rotate(-45deg); /* IE 9 */
    transform: rotate(-45deg);
}

.container .success {
    color: #108548;
}

.container .failure {
    color: #dd2b0e;
}

.container .warning {
    color: #ffc905;
}

.container.loading {
    justify-content: center;
}

.container.loading .spinner {
    color: rebeccapurple;
}
