.header{
    height: 6rem;
    background: rebeccapurple;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header h3{
    color: white;
    margin-bottom: 0;
}

.header h3.link:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.header h3.link:hover{
    color: #DCDCDC;
}

.header .profile{
    position: absolute;
    right: 1rem;
    color: white;
    display: flex;
    flex-direction: row;
}

.header .profile span{
    font-size: 1.5rem;
}

.header .profile .logout{
    background: rebeccapurple !important;
    border: 0 !important;
    transition: none;
}