* {
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
}

#root {
  height: 100vh;
  font-family: sans-serif;
}
