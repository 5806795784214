.searchBar {
    width: 16rem;
    background: #F2F1F9;
    border: none;
    padding: .5rem;
    margin-right: .1rem;
    height: 2rem;
}

.searchBarContainer {
    background: #F2F1F9;
    height: 2rem;
}

.searchBarSearch {
    color: #663399 !important;
    background: #F2F1F9 !important;
    border: 0 !important;
    padding: 0;
    vertical-align: initial;
}