.footer{
    background: rebeccapurple;
    width: 100%;
    bottom: 0;
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.footer h4 {
    color: white;
    font-weight: 500;
    text-align: center;
    margin-bottom: 0;
}