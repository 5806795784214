.item {
    border: .1rem rebeccapurple solid;
    border-radius: .5rem;
    margin: .5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.item .body{
    margin-top: .5rem;
    margin-left: 1rem;
    margin-bottom: .5rem;
    width: 90%;
}

.item .body h3{
    margin-bottom: 0;
}

.item .body h3:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.item .body h3 .item_title:hover{
    text-decoration: underline;
}

.item .body h6{
    margin-bottom: 0;
}

.item .body p{
    margin-bottom: 0;
}

.item .body .dateTime{
    font-size: small;
}

.item .body .text{
    list-style-position: inside;
}

.item .body .text .language-suggestion{
    background-color: #7094ff;
    width: 100%;
}

.item .body .text .code{
    border: 1px solid black;
    border-radius: 2px;
    padding: 2px;
    padding-left: 4px;
}

.item .body .text img{
    width: 100%;
}